import React, { useEffect, useState } from "react";
import { Provider } from "mobx-react";
import stores from "../stores";
import AuthUserService from "../services/AuthUserService";
import PageLayout from "./layout/Layout";

// eslint-disable-next-line import/order
import GoogleConsentModal from "@components/page/GoogleConsentModal"; // Need to be below the "stores" and "AuthUserService" imports
import { getCookie } from "../utils/functions";
import "react-calendar/dist/Calendar.css";

const getRequestParamsObj = () => {
  if (typeof window !== "undefined") {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const utmSource = urlParams.get("utm_source");
    const utmMedium = urlParams.get("utm_medium");
    const utmCampaign = urlParams.get("utm_campaign");

    const requestObj = {
      utm_source: utmSource || "",
      utm_medium: utmMedium || "",
      utm_campaign: utmCampaign || ""
    };
    return requestObj;
  }
  return {};
};

const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }

  document.cookie = `${name}=${value || ""}${expires};domain=.boleron.bg;path=/`;
};

const requestParams = getRequestParamsObj();
if (
  requestParams.utm_source === "android" ||
  requestParams.utm_source === "ios" ||
  requestParams.utm_source === "biala_karta" ||
  requestParams.utm_source === "easy_credit" ||
  requestParams.utm_source === "viva_credit" ||
  requestParams.utm_source === "bulsatcom" ||
  requestParams.utm_source === "icard"
) {
  const encoded = btoa(JSON.stringify(requestParams));
  setCookie("boleron_affiliate", encoded, 90);
}

const FireBaseApp = ({ element, showElementDirectly }) => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    AuthUserService.subscribeAuthChange(async user => {
      if (user) {
        // ако вече е логнат добавяме юзъра в state
        stores.auth.setUser(user);
      } else {
        // ако не е логнат създаваме анонимен потребител
        try {
          await AuthUserService.loginAnonymous();
        } catch (err) {
          console.log("err", err);
        }
      }

      setLoggedIn(true);
    });
  }, []);

  return <Provider {...stores}>{loggedIn || showElementDirectly ? element : <PageLayout title=" " navbarWorkflow loading={true} />}</Provider>;
};

const Wrapper = ({ element }) => {
  let showElementDirectly = true;
  let isShowConsent = false;
  if (typeof window !== "undefined") {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const pathName = window?.location?.pathname;
    const patterns = ["policy-payment", "workflow"];
    showElementDirectly = !patterns.some(pattern => pathName.includes(pattern));
    const utmMedium = urlParams.get("utm_medium");
    const utmSource = urlParams.get("utm_source");

    if (utmMedium === "email") {
      window.sessionStorage.setItem("utm_medium", "email");
    }
    const GATSBY_CONFIGURATION = process.env.GATSBY_CONFIGURATION || "boleron";
    const hasUserAcceptedConsent = getCookie("boleron-google-consent");
    const isReactNativeWebView = window ? window.sessionStorage.getItem("hideHeader") === "true" : false;
    const isEmailSet = window.sessionStorage.getItem("utm_medium") === "email";
    const isMobileApp = utmSource === "ios" || utmSource === "android";
    isShowConsent = GATSBY_CONFIGURATION === "boleron" && !hasUserAcceptedConsent && !isReactNativeWebView && !isEmailSet && !isMobileApp;

    if (hasUserAcceptedConsent) {
      try {
        window.gtag("consent", "update", {
          ad_storage: "granted",
          ad_user_data: "granted",
          ad_personalization: "granted",
          analytics_storage: "granted"
        });
      } catch (err) {}
    }
  }

  return (
    <>
      {isShowConsent && <GoogleConsentModal />}

      <FireBaseApp element={element} showElementDirectly={showElementDirectly} />
    </>
  );
};

export default Wrapper;
