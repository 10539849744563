import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link, useTranslation, useI18next, I18nextContext } from "gatsby-plugin-react-i18next";

import UserIcon from "@icons/user-navigation-icon.svg";

import AppStoreIcon from "@icons/app-store-black-icon.svg";
import GooglePlayIcon from "@icons/google-play-black-icon.svg";
import ShieldIcon from "@icons/shield.svg";
import { navigate } from "gatsby";
import HamburgerOpen from "@icons/hamburger_menu_header_open.svg";
import HamburgerCloseMobile from "@icons/hamburger_menu_header_close_mobile.svg";
import HamburgerClose from "@icons/hamburger_menu_header_close.svg";
import BackButton from "./BackButton";
import MobileContent from "./MobileContent";
import { LanguageSwitcherDesktop, LanguageSwitcherMobile } from "./LanguageSwitchers";
import IconDoubleArrow from "../../images/icons/double-arrow.svg";
import useBreakpoints from "../../hooks/useBreakpoints";
import { getActiveProductsFromConfig, getHeader, getHeaderLink, getHeaderLogo, showHeaderLink } from "../../config/activeProducts/helpers";
import ProductsService from "../../services/ProductsService";
import { GATSBY_CONFIGURATION } from "../../config/activeProducts";

const Logo = getHeaderLogo();
const headerFromConfig = getHeader();

const NavbarItem = ({ linkTo, label, onClick }) => (
  <div className="is-flex navbar-item-div">
    <IconDoubleArrow
      style={{
        width: 15,
        backgroundRepeat: "no-repeat",
        marginBottom: 24
      }}
    />
    <Link className="navbar-item" activeClassName="active" onClick={onClick} to={linkTo}>
      {label}
    </Link>
  </div>
);

// TODO: Rewrite the scroll logic

const Navigation = ({ showBackButton, auth, inWorkflow, title, navClasses }) => {
  const { languages, originalPath } = useI18next();
  const { isDesktop } = useBreakpoints();
  // const languageContext = React.useContext(I18nextContext)
  const currentLanguage = React.useContext(I18nextContext).language;
  const langSwitcherProps = {
    originalPath,
    languages,
    currentLanguage
  };
  const { t } = useTranslation();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isNavbarWorkflowMenuOpen, setNavbarWorkflowMenuOpen] = useState(false);

  const welcomeMessage = GATSBY_CONFIGURATION === "boleron" ? t("Welcome to Boleron", "Добре дошъл!") : t("Welcome", "Добре дошъл!");

  const closeMenu = e => {
    // e.preventDefault;
    if (!document.querySelector(".sideMenuWrapper").contains(e.target)) {
      setNavbarWorkflowMenuOpen(!isNavbarWorkflowMenuOpen);
    }
  };

  const renderProducts = () => {
    const arrayOfSections = [];

    const countOfProductsInSection = 3;
    const toIndex = Math.ceil(getActiveProductsFromConfig().length / countOfProductsInSection);
    for (let i = 0; i < toIndex; i++) {
      // temporary workaround - new products title length
      const menuWidthStyle = GATSBY_CONFIGURATION === "boleron" ? (i === 0 ? { minWidth: 265 } : { minWidth: 250 }) : {};

      const productsData =
        GATSBY_CONFIGURATION === "boleron"
          ? getActiveProductsFromConfig().filter(productObj => productObj.id !== "EXT" && !productObj.isHiddenInMainProducts)
          : getActiveProductsFromConfig();

      const productsHtml = productsData?.slice(i * countOfProductsInSection, (i + 1) * countOfProductsInSection)?.map(p => (
        <div key={p?.id} className="is-flex navbar-item-div" style={menuWidthStyle}>
          {ProductsService.getProductsMenuIcons(p?.id)}
          <Link
            className="navbar-item"
            activeClassName="active"
            style={{ textWrap: "wrap", flexShrink: 1, boxSizing: "border-box" }}
            to={p?.link}
            onClick={() => setMobileMenuOpen(false)}
          >
            {ProductsService.getProductName(p?.id, currentLanguage)}
          </Link>
        </div>
      ));

      arrayOfSections.push(
        <div key={`nav-section-${i}`} className={i > 0 ? "ml-3" : ""}>
          {productsHtml}
        </div>
      );
    }

    return arrayOfSections;
  };

  useEffect(() => {
    if (isNavbarWorkflowMenuOpen) {
      document.body.style.paddingRight = `${window.innerWidth - document.body.clientWidth}px`;
      document.documentElement.style.overflow = "hidden";
    } else {
      document.body.style.paddingRight = 0;
      document.documentElement.style.overflow = "";
    }
  }, [isNavbarWorkflowMenuOpen]);

  return (
    <nav className={`navbar ${navClasses} is-transparent`} role="navigation" aria-label="main navigation">
      <div
        className={inWorkflow ? "is-flex is-justify-content-space-between px-5 is-align-items-center" : "container is-desktop-only"}
        style={
          inWorkflow
            ? {
                width: "100%",
                minHeight: GATSBY_CONFIGURATION === "vivacom" ? 120 : 100
              }
            : { maxWidth: 1200 }
        }
      >
        {inWorkflow ? (
          <>
            {showBackButton && (
              <div style={{ display: "flex", height: 76, alignItems: "center" }} className="is-clickable" data-cy-back onClick={() => navigate(-1)}>
                <BackButton />
                <p className="is-uppercase is-hidden-touch regular-txt" style={{ paddingLeft: 7, paddingTop: 2 }}>
                  {t("Back", "Назад")}
                </p>
              </div>
            )}
            {!showBackButton && <div style={{ width: isDesktop ? 94 : 31, height: 76 }} />}
            <div className="product-header-workflow px-2">
              {title && (
                <div className="productHeaderWorkflow has-text-centered" style={{ minWidth: "100%" }}>
                  {title}
                </div>
              )}

              {!title && (
                <Link className="navbar-item is-block" to="/" onClick={() => setMobileMenuOpen(false)}>
                  {Logo}
                  {!headerFromConfig.logoText.disabled && (
                    <div
                      className="is-uppercase"
                      style={{
                        marginTop: "-7px",
                        letterSpacing: { bg: "1px", en: "2.8px", ru: "3.5px" }[currentLanguage],
                        paddingLeft: 8,
                        textAlign: "center",
                        fontSize: 12
                      }}
                    >
                      {t("online_insurance", "Онлайн застраховки")}
                    </div>
                  )}
                </Link>
              )}

              <div
                id="navbarMenu"
                /* is-slide-out-animated is-clip-path-animated */
                // style={isNavbarWorkflowMenuOpen ? { display: "absolute", top: 0 } : { display: "none" }}
                className={`is-hidden-touch navbar-menu is-clip-path-animated ${isNavbarWorkflowMenuOpen ? "" : "is-hidden-desktop"}`}
              >
                <div className="navbar-menu-background-active" onClick={e => closeMenu(e)}>
                  <div className="sideMenuWrapper">
                    <div className="navbar-end" style={{ display: "flex", flexDirection: "column", marginLeft: 0 }}>
                      <div className="header-navbar-end" style={{ width: "100%" }}>
                        <div
                          onClick={() => setNavbarWorkflowMenuOpen(!isNavbarWorkflowMenuOpen)}
                          className="is-flex is-clickable is-align-items-center is-flex-direction-row-reverse	"
                          data-cy="data-cy-hamburgerClosed"
                        >
                          <p className="is-uppercase ml-1 is-hidden-touch regular-txt" style={{ paddingTop: 2 }}>
                            {t("Menu", "Меню")}
                          </p>
                          <HamburgerClose style={{ fill: "white", height: 27, width: 27 }} />
                        </div>
                        <div>
                          {auth?.user && !auth?.user?.isAnonymous ? (
                            <p className="regular-txt" style={{ fontSize: 20 }}>
                              {auth?.user?.email}
                            </p>
                          ) : (
                            <div className="is-flex">
                              <ShieldIcon style={{ width: 42, height: "auto" }} className="mr-2" />
                              <div className="header-navbar-end-content">
                                <p>{t("Hello", "Здравей!")}</p>
                                <p>{welcomeMessage}</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <MobileContent auth={auth} />
                    </div>
                  </div>
                </div>
              </div>

              {/* Navigation menu MOBILE version */}
              <div
                id="navbarMenu"
                /* is-slide-out-animated is-clip-path-animated */
                style={{ top: 0, padding: 0, height: "100vh" }}
                className={`is-hidden-desktop navbar-menu is-clip-path-animated ${isNavbarWorkflowMenuOpen ? "is-active" : ""}`}
              >
                <div className="navbar-end">
                  <div className="header-navbar-end">
                    {/* {auth.isAuthenticated ? ( */}
                    {auth?.user && !auth?.user.isAnonymous ? (
                      <p className="regular-txt" style={{ fontSize: 20 }}>
                        {auth?.user?.email}
                      </p>
                    ) : (
                      <>
                        <ShieldIcon style={{ width: 42, height: "auto" }} className="mr-2" />
                        <div className="header-navbar-end-content">
                          <p>{t("Hello", "Здравей!")}</p>
                          <p>{welcomeMessage}</p>
                        </div>
                      </>
                    )}
                    <div
                      style={{
                        width: 60,
                        height: 40,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center"
                      }}
                      onClick={() => setNavbarWorkflowMenuOpen(!isNavbarWorkflowMenuOpen)}
                    >
                      <HamburgerCloseMobile />
                    </div>{" "}
                  </div>
                  <MobileContent auth={auth} />
                </div>
              </div>
            </div>
            <div
              onClick={() => setNavbarWorkflowMenuOpen(!isNavbarWorkflowMenuOpen)}
              className="is-flex is-flex-direction-row-reverse is-clickable"
              style={{ display: "flex", alignItems: "center", height: 76 }}
              data-cy="data-cy-hamburgerOpen"
            >
              <p className="is-uppercase ml-1 is-hidden-touch regular-txt" style={{ paddingTop: 2 }}>
                {t("Menu", "Меню")}
              </p>
              <HamburgerOpen />
            </div>
          </>
        ) : (
          <>
            <div className="navbar-brand">
              {showBackButton && (
                <div style={{ display: "flex", zIndex: 1000 }} className="is-clickable" data-cy-back onClick={() => navigate(-1)}>
                  <BackButton style={{ width: "auto" }} />
                </div>
              )}
              {!showBackButton && <div className="emptyDivHeader" />}
              <Link className="navbar-item is-block" to="/" onClick={() => setMobileMenuOpen(false)}>
                {Logo}
                {!headerFromConfig.logoText.disabled && (
                  <div
                    className="is-uppercase"
                    style={{
                      marginTop: "-7px",
                      letterSpacing: { bg: "1px", en: "2.8px", ru: "3.5px" }[currentLanguage],
                      paddingLeft: 8,
                      textAlign: "center",
                      fontSize: 12
                    }}
                  >
                    {t("online_insurance", "Онлайн застраховки")}
                  </div>
                )}
              </Link>
              {/* <a
            className={`navbar-burger ${isMobileMenuOpen ? "is-active" : ""}`}
            type="button"
            style={{ width: 20, height: 20, marginRight: 20 }}
            onClick={() => {
              // setMobileMenuOpen(!isMobileMenuOpen);
              setNavbarWorkflowMenuOpen(!isNavbarWorkflowMenuOpen);
            }}
            aria-label="menu"
            aria-expanded="false"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a> */}
              <div
                onClick={() => setNavbarWorkflowMenuOpen(!isNavbarWorkflowMenuOpen)}
                className="is-flex is-flex-direction-row-reverse is-clickable is-hidden-desktop"
                style={{ display: "flex", alignItems: "center", height: 76, marginRight: 14 }}
              >
                <HamburgerOpen style={{ margin: "0.5rem" }} />
              </div>
            </div>

            {/*Menu Desktop Home*/}
            <div
              id="navbarMenu"
              /* is-slide-out-animated is-clip-path-animated */
              className={`is-hidden-touch navbar-menu is-clip-path-animated is-justify-content-end ${isMobileMenuOpen ? "is-active" : ""}`}
            >
              <div className="navbar-end">
                {GATSBY_CONFIGURATION === "boleron" && <LanguageSwitcherMobile {...langSwitcherProps} />}
                <Link className="navbar-item navbar-item-login is-hidden-desktop lvl-1" to="/policies">
                  <span className="icon-text">
                    <span className="icon">
                      <ShieldIcon />
                    </span>
                    <span>{t("Login", "Вход / Моите полици")}</span>
                  </span>
                </Link>
                <Link className="navbar-item lvl-1 pr-4" activeClassName="active" to="/">
                  {t("Home", "Начало")}
                </Link>
                <div id="mainProducts" className="navbar-item has-dropdown is-hoverable lvl-1">
                  <span className="navbar-link">{t("Products", "Застраховки")}</span>
                  <div className="navbar-dropdown is-boxed">
                    <div
                      className={`is-flex-desktop is-flex-direction-row ${
                        headerFromConfig.downloadApp.disabled ? "is-justify-content-center" : "is-justify-content-space-between"
                      }`}
                    >
                      <div>
                        <h4 className="is-uppercase is-hidden-touch pt-2 pb-5 pl-1" style={{ fontSize: 22 }}>
                          {t("Products", "Застраховки")}
                        </h4>
                        <div className="navbar-products-container">{renderProducts()}</div>
                      </div>

                      {!headerFromConfig.downloadApp.disabled && (
                        <div className="bg-smartphones is-flex-grow-1 is-hidden-touch is-flex is-align-items-center is-justify-content-center ">
                          <img loading="eager" src="/png/home-page-phone-header.png" alt="Download application banner" style={{ minWidth: 140, maxWidth: 140 }} />

                          <div>
                            <p className="mb-4 is-size-5 is-extra-bold-family is-grey-text">{t("Download App", "Свали приложението")}</p>

                            <a className="is-fullwidth mb-3" href="https://apps.apple.com/bg/app/boleron/id1476539247" target="_blank" rel="noreferrer">
                              <AppStoreIcon />
                            </a>

                            <a className="is-fullwidth" href="https://play.google.com/store/apps/details?id=com.boleron" target="_blank" rel="noreferrer">
                              <GooglePlayIcon />
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {currentLanguage === "bg" && (
                  <Link className="navbar-item lvl-1 is-hidden-desktop" activeClassName="active" onClick={() => setMobileMenuOpen(false)} to="/proverka-globi-kat-grazhdanska/">
                    {t("Check for fines from KAT", "проверка за глоби от кат")}
                  </Link>
                )}
                {showHeaderLink("services") && (
                  <div className="navbar-item has-dropdown is-hoverable lvl-1">
                    <span className="navbar-link">{t("Services", "Услуги")}</span>
                    <div className="navbar-dropdown is-boxed is-navbar-dropdown-centered-services">
                      <div className="is-flex-desktop is-flex-direction-row is-justify-content-space-between">
                        <div>
                          <h4 className="is-uppercase is-hidden-touch pt-2 pb-5 pl-1" style={{ fontSize: 22 }}>
                            {t("Services", "Услуги")}
                          </h4>

                          <div className="nav-services-link-container">
                            <NavbarItem linkTo="/proverka-globi-kat-grazhdanska/" onClick={() => setMobileMenuOpen(false)} label={t("Check for fines from KAT")} />
                            <NavbarItem linkTo="/proverka-grazhdanska/" onClick={() => setMobileMenuOpen(false)} label={t("GO CHECK")} />
                            <NavbarItem linkTo="/proverka-vig/" onClick={() => setMobileMenuOpen(false)} label={t("INSPECTION/PURCHASE OF VIGNETTE")} />
                            <NavbarItem linkTo="/vehicle-history/" onClick={() => setMobileMenuOpen(false)} label={t("Check for damage")} />
                            <NavbarItem linkTo="/proverka-tehnicheski-pregled/" onClick={() => setMobileMenuOpen(false)} label={t("GTP CHECK")} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {showHeaderLink("aboutUs") && (
                  <Link
                    className="navbar-item lvl-1 pr-4 "
                    onClick={() => setMobileMenuOpen(false)}
                    activeClassName="active"
                    // to="за-Нас"
                    to={getHeaderLink("aboutUs")?.url}
                  >
                    {t("AboutUs", "За Нас")}
                  </Link>
                )}
                {showHeaderLink("contacts") && (
                  <Link className="navbar-item lvl-1 pr-4 " activeClassName="active" onClick={() => setMobileMenuOpen(false)} to={getHeaderLink("contacts")?.url}>
                    {t("Contacts", "Контакти")}
                  </Link>
                )}

                {showHeaderLink("myProfile") && (
                  <div className="navbar-item has-dropdown is-hoverable lvl-1">
                    <div className="navbar-item is-hidden-touch">
                      <Link
                        className="button is-info is-rounded is-low theme-button my-profile-button"
                        to={auth?.user && !auth?.user?.isAnonymous ? "/my-profile-page" : getHeaderLink("myProfile")?.url}
                      >
                        <span className="icon is-30px-to-30px">
                          <UserIcon className="is-30px-to-30px" />
                        </span>
                        <span className="is-uppercase bold-txt is-size-4" title="Моите Полици">
                          {t("My profile", "Моят профил")}
                        </span>
                      </Link>
                    </div>
                  </div>
                )}

                {showHeaderLink("blog") && (
                  <Link className="navbar-item lvl-1 pr-4 pl-3" onClick={() => setMobileMenuOpen(false)} to={getHeaderLink("blog")?.url}>
                    {t("Blog", "Блог")}
                  </Link>
                )}

                {GATSBY_CONFIGURATION === "boleron" && <LanguageSwitcherDesktop {...langSwitcherProps} />}
              </div>
            </div>

            {/*Menu Mobile Home*/}
            <div
              id="navbarMenu"
              /* is-slide-out-animated is-clip-path-animated */
              style={{ top: 0, padding: 0, height: "100vh" }}
              className={`is-hidden-desktop navbar-menu is-clip-path-animated ${isNavbarWorkflowMenuOpen ? "is-active" : ""}`}
            >
              <div className="navbar-end">
                <div className="header-navbar-end">
                  {/* {auth.isAuthenticated ? ( */}
                  {auth?.user && !auth?.user.isAnonymous ? (
                    <p className="regular-txt" style={{ fontSize: 20 }}>
                      {auth?.user.email}
                    </p>
                  ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ShieldIcon style={{ width: 42, height: "auto" }} className="mr-2" />
                      <div className="header-navbar-end-content">
                        <p>{t("Hello", "Здравей!")}</p>
                        <p>{welcomeMessage}</p>
                      </div>
                    </div>
                  )}
                  <div
                    style={{ width: 60, height: 40, display: "flex", justifyContent: "flex-end", alignItems: "center" }}
                    onClick={() => setNavbarWorkflowMenuOpen(!isNavbarWorkflowMenuOpen)}
                  >
                    <HamburgerCloseMobile />
                  </div>
                </div>
                <MobileContent
                  auth={auth}
                  originalPath={originalPath}
                  languages={languages}
                  currentLanguage={currentLanguage}
                  containsLanguageSwitcher={GATSBY_CONFIGURATION === "boleron"}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navigation;
