export const actionTypes = {
  POLICY_REQUEST_CARD: "policyRequestCard",
  POLICY_REQUEST_EXISTING_CARD: "policyRequestExistingCard",
  POLICY_REQUEST_CASH_ON_DELIVERY: "policyRequestCashOnDelivery",
  POLICY_REQUEST_BANK_TRANSFER: "policyRequestBankTransfer",
  POLICY_OFFER: "policyOffer",
  PAY_INSTALLMENT_CARD: "payInstallmentCard",
  PAY_INSTALLMENT_EXISTING_CARD: "payInstallmentExistingCard",
  PAY_INSTALLMENT_CASH_ON_DELIVERY: "payInstallmentCashOnDelivery",
  PAY_INSTALLMENT_BANK_TRANSFER: "payInstallmentBankTransfer",
  CUSTOM: "custom", // used for purchase flow - vignette and vin history
  INVALID: "invalid"
};
