import { useState, useEffect } from "react";

const useBreakpoints = () => {
  // Set initial breakpoint to "desktop" for SSR
  const [breakpoint, setBreakpoint] = useState("desktop");

  useEffect(() => {
    if (typeof window !== "object") {
      return;
    }

    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setBreakpoint("mobile");
      } else if (width < 1024) {
        setBreakpoint("tablet");
      } else {
        setBreakpoint("desktop");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return {
    isMobile: breakpoint === "mobile",
    isTablet: breakpoint === "tablet",
    isDesktop: breakpoint === "desktop",
    currentBreakpoint: breakpoint
  };
};

export default useBreakpoints;
